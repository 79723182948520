import(/* webpackMode: "eager", webpackExports: ["PrismicNextImage"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextLink"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicPreviewClient"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicToolbar"] */ "/vercel/path0/node_modules/@prismicio/react/dist/PrismicToolbar.js");
;
import(/* webpackMode: "eager", webpackExports: ["div"] */ "/vercel/path0/node_modules/motion/dist/es/motion/lib/react-client.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/lib/ErrorBoundary.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/AboutUsTestimonials/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/ContactForm/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Hero/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/HomeCarousel/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/HomeInstagram/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/HomeTravelCarousel/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/HoneymoonDesign/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/HoneymoonFlipbook/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Navigation/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Redirect/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/TravelTrips/index.tsx");
